import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchAlarmDeviceList, addAlarmDevice, delAlarmDevice, updateAlarmDevice } from "../../../api/charge"; // import {} from 'iview-area'

export default {
  name: "AlarmDevice",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        is_enable: 0
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      villageDialog: false,
      dialogForm: {
        id: "",
        name: "",
        uid: "",
        address: "",
        url: '',
        is_enable: true
      },
      textMap: {
        update: "编辑设备",
        create: "添加设备"
      },
      dialogTitle: "",
      villageLoading: false,
      villageOptions: [],
      btnLoading: false,
      rules: {
        name: [{
          required: true,
          message: "设备名称不能为空"
        }],
        uid: [{
          required: true,
          message: "设备UID必填",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.searchForm.keyword = this.$route.query ? this.$route.query.keyword : '';
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchAlarmDeviceList(data).then(function (response) {
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      var data = Object.assign({}, this.dialogForm);
      addAlarmDevice(data).then(function (res) {
        _this2.$message({
          type: "success",
          message: "添加设备成功"
        });

        _this2.villageDialog = false;

        _this2.getTableData();
      }).catch(function (err) {
        _this2.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleEdit: function handleEdit(row) {
      var data = Object.assign({}, row);
      this.dialogTitle = "update";
      this.villageDialog = true;
      this.dialogForm = {
        id: data.id,
        name: data.name,
        uid: data.uid,
        address: data.address,
        url: data.url,
        is_enable: data.is_enable == 1 ? true : false
      };
    },
    handleEditBtn: function handleEditBtn() {
      var _this3 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this3.dialogForm);
          updateAlarmDevice(data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this3.$message({
              type: "success",
              message: "编辑设备成功"
            });

            _this3.villageDialog = false;

            _this3.getTableData();
          }).catch(function (err) {
            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    resetProperty: function resetProperty() {
      this.dialogForm = {
        id: "",
        name: "",
        uid: "",
        address: "",
        url: '',
        is_enable: true
      };
    },
    handleAdd: function handleAdd() {
      var _this4 = this;

      this.resetProperty();
      this.villageDialog = true;
      this.dialogTitle = "create";
      this.$nextTick(function () {
        _this4.$refs["dialogForm"].clearValidate();
      });
    },
    handleDel: function handleDel(row) {
      var _this5 = this;

      console.log("删除设备");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u8BBE\u5907 ".concat(row.name, "?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = row.id;
        delAlarmDevice(data).then(function (res) {
          console.log(res);

          _this5.$message({
            type: "success",
            message: "删除设备成功"
          });

          var index = _this5.dataList.indexOf(row);

          _this5.dataList.splice(index, 1);
        }).catch(function (err) {
          _this5.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    }
  }
};